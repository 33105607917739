<template>
    <page
        id="ping"
        title=""
        :status="page_status"
        :waiting="!data_version && loading"
        :is-root="true"
        :global-search-handler="center_on_listing"
        @statused="statused"
    >
        <template v-slot:page-title>&nbsp;</template>
        <div class="d-flex d-flex-columns">
            <expandable class="flex-static mb-8">
                <template v-slot:title>
                    {{ translate("Filter Shown Listings") }}
                </template>
                <listing-search-panel
                    :query="state.advanced_query"
                    @input="adjust_query"
                />
            </expandable>
            <div class="flex-grow columns ping-container">
                <div class="column col-4 col-xl-12">
                    <div v-if="!!statistics">
                        <div class="mb-4 show-sm">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td class="text-bold">Avg Listing Price</td>
                                        <td class="text-right">{{ (statistics.price.avg / 100) | nibnut.currency }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-bold">Listings</td>
                                        <td class="text-right">{{ statistics.counts.active | nibnut.number("0,0") }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-bold">{{ translate('Price/Sale') }}</td>
                                        <td class="text-right">{{ (statistics.price_ratio.avg / 100) | nibnut.number("0,0") }}&nbsp;%</td>
                                    </tr>
                                    <tr>
                                        <td class="text-bold">{{ translate('D.O.M.') }}</td>
                                        <td class="text-right">{{ statistics.days_on_market.avg | nibnut.number("0,0") }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-bold">Avg Sale Price</td>
                                        <td class="text-right">{{ (statistics.sale_price.avg / 100) | nibnut.currency }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-bold">Sales</td>
                                        <td class="text-right">{{ statistics.counts.sold | nibnut.number("0,0") }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-bold">Lowest Sale Price</td>
                                        <td class="text-right">
                                            {{ (statistics.sale_price.min / 100) | nibnut.currency }}
                                            <div
                                                v-if="!!statistics.sale_price.min_line1"
                                                class="text-small text-gray"
                                            >
                                                {{ statistics.sale_price.min_line1 }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-bold">Highest Sale Price</td>
                                        <td class="text-right">
                                            {{ (statistics.sale_price.max / 100) | nibnut.currency }}
                                            <div
                                                v-if="!!statistics.sale_price.max_line1"
                                                class="text-small text-gray"
                                            >
                                                {{ statistics.sale_price.max_line1 }}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="columns justify-content-center hide-sm">
                            <div class="column col-6 col-xl-3 col-md-4 col-sm-12 mb-4">
                                <counted-statistic
                                    title="Avg Listing Price"
                                    :count="(statistics.price.avg / 100) || 0"
                                    :as-dollars="true"
                                    color="dark"
                                />
                            </div>
                            <div class="column col-6 col-xl-3 col-md-4 col-sm-12 mb-4">
                                <counted-statistic
                                    title="Listings"
                                    :count="statistics.counts.active || 0"
                                    color="dark"
                                />
                            </div>
                            <div class="column col-6 col-xl-3 col-md-4 col-sm-12 mb-4">
                                <counted-statistic
                                    :title="translate('Price/Sale')"
                                    :count="(statistics.price_ratio.avg / 100) || 0"
                                    :as-percentage="true"
                                    color="dark"
                                />
                            </div>
                            <div class="column col-6 col-xl-3 col-md-4 col-sm-12 mb-4">
                                <counted-statistic
                                    :title="translate('D.O.M.')"
                                    :count="statistics.days_on_market.avg || 0"
                                    color="dark"
                                />
                            </div>
                            <div class="column col-6 col-xl-3 col-md-4 col-sm-12 mb-4">
                                <counted-statistic
                                    title="Avg Sale Price"
                                    :count="(statistics.sale_price.avg / 100) || 0"
                                    :as-dollars="true"
                                    color="dark"
                                />
                            </div>
                            <div class="column col-6 col-xl-3 col-md-4 col-sm-12 mb-4">
                                <counted-statistic
                                    title="Sales"
                                    :count="statistics.counts.sold || 0"
                                    color="dark"
                                />
                            </div>
                            <div class="column col-6 col-xl-3 col-md-4 col-sm-12 mb-4">
                                <counted-statistic
                                    title="Highest Sale Price"
                                    :count="(statistics.sale_price.max / 100) || 0"
                                    color="dark"
                                >
                                    <template v-slot:controls>
                                        <div
                                            v-if="!!statistics.sale_price.max_line1"
                                            class="p-absolute text-small text-gray"
                                        >
                                            {{ statistics.sale_price.max_line1 }}
                                        </div>
                                    </template>
                                </counted-statistic>
                            </div>
                            <div class="column col-6 col-xl-3 col-md-4 col-sm-12 mb-4">
                                <counted-statistic
                                    title="Lowest Sale Price"
                                    :count="(statistics.sale_price.min / 100) || 0"
                                    color="dark"
                                >
                                    <template v-slot:controls>
                                        <div
                                            v-if="!!statistics.sale_price.min_line1"
                                            class="p-absolute text-small text-gray"
                                        >
                                            {{ statistics.sale_price.min_line1 }}
                                        </div>
                                    </template>
                                </counted-statistic>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column col-8 col-xl-12">
                    <map-loader
                        id="ping-map"
                        :listings="pins"
                        :uses-geolocation="true"
                        :anchored-to="anchored_to"
                        height="100%"
                        @input="change_bounds"
                    />
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import debounce from "lodash/debounce"

import { is_page, is_remote_data_table_source } from "@/nibnut/mixins"
import { displays_listing } from "@/custom/mixins"

import { MapLoader } from "@/nibnut/components"
import {
    Expandable,
    CountedStatistic,
    ListingSearchPanel
} from "@/custom/components"

const bounds_slug = bounds => {
    if(!bounds) return undefined
    const area = bounds.area ? `${bounds.area.xmin},${bounds.area.ymin},${bounds.area.ymax},${bounds.area.xmin},${bounds.area.center.lat},${bounds.area.center.lng},${bounds.area.radius_center.lat},${bounds.area.radius_center.lng},${bounds.area.radius}` : "-"
    return area
}

export default {
    name: "Ping",
    mixins: [is_page, is_remote_data_table_source, displays_listing],
    components: {
        MapLoader,
        Expandable,
        CountedStatistic,
        ListingSearchPanel
    },
    methods: {
        pre_load () {
            if(!Object.keys(this.state.advanced_query).length) {
                this.state.advanced_query = {
                    ...this.constants("base_listing_query"),
                    types: this.default_term_ids("VOCABULARY_PROPERTY_TYPES"),
                    neighborhoods: this.profile.neighborhood_ids || [],
                    or_sold_within: 90
                }
            }
        },
        post_load (response) {
            if(response.meta) {
                this.statistics = {
                    price: response.meta.price,
                    sale_price: response.meta.sale_price,
                    counts: response.meta.counts,
                    price_ratio: response.meta.price_ratio,
                    days_on_market: response.meta.days_on_market
                }
            } else this.statistics = null
        },
        adjust_query (value, name) {
            if(this.state.advanced_query[name] !== value) {
                const advanced_query = { ...this.state.advanced_query }
                advanced_query[name] = value
                this.filtered_by_selection = false
                this.set_state_values({ advanced_query })
                this.refresh()
            }
        },
        change_bounds: debounce(function (bounds) {
            if(bounds_slug(bounds) !== bounds_slug(this.state)) {
                this.set_state_values({ area: bounds.area })
                this.refresh()
            }
        }, 500),
        center_on_listing (search_result) {
            if(search_result && search_result._entity) {
                if((search_result._entity === "listing") && !!search_result.latitude && !!search_result.longitude) this.anchored_to = search_result
                else this.$router.push({ name: `${search_result._entity}.edit`, params: { id: search_result.slug || search_result.id } })
            }
        }
    },
    computed: {
        can_refresh () {
            return this.profile_id && !!this.state.area
        },
        fields () {
            return ["fieldset::map"]
        },
        pins () {
            const pins = [...this.filtered_rows]
            if(this.anchored_to) pins.push(this.anchored_to)
            return pins
        }
    },
    data () {
        return {
            entity: "listing",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                advanced_query: {},
                area: undefined
            },
            anchored_to: null,
            statistics: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-app.ping .non-navigation main > div {
    &, & #content, & #ping, & #ping .d-flex.d-flex-columns {
        height: 100%;
    }
    .counted-statistic .card-body {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
    .nibnut-map {
        min-height: 320px;
    }
    @media (max-width: $size-xl) {
        .ping-container {
            flex-direction: column;
        }
        .tooltip, .tooltip.tooltip-top, .tooltip.tooltip-right, .tooltip.tooltip-bottom, .tooltip.tooltip-left {
            position: relative;
        }
    }
}
</style>
